@font-face {
    font-family: Brown;
    src: url('./fonts/BrownFont/Brown-Regular.otf');
  }
@font-face {
  font-family: 'Sohne-Kraftig';
  src: url('./fonts/Sohne-Kraftig.otf');
}

@font-face {
  font-family: 'Sohne-Halbfett';
  src: url('./fonts/Sohne-Halbfett.otf');
}


@font-face {
  font-family: 'Sohne-Leicht';
  src: url('./fonts/Sohne-Leicht.otf');
}
@font-face {
  font-family: 'PPEiko-Thin';
  src: url('./fonts/PPEiko-Thin.otf') format('otf');
}

@font-face {
  font-family: 'PPEiko-Thin-100';
  src: url('./fonts/PPEiko-Thin.otf') format('ttf');
}
