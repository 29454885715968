@import "../../../shared/shared.scss";
.menu {
    padding-top: 70px; 
    list-style-type: none;
  }

.side-menu-main {
    margin-bottom: 40px;
    height: 350px;
}
.menu-group {
    width: 10%;
    height: 390px;
    margin-top: 665px;
    position: absolute;
    right: 35px;
    border-radius: 25px;
    padding-top: 5px;
}

.menu-items {
    width: 10%;
    height: 50px;
    margin-top: 780px;
    position: absolute;
    right: 50px;
}

.menu-group-item {
    margin-top: 115px;
    list-style-type: none;
    font-size: 24px;
    text-align: center;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-left: -15px;
    @include font-header;

    color: $rollover;
    cursor: pointer;
    &:hover {
        color: white;
    }
}

.menu-item {
    background-color: #60343c;
    width: 134px;
    height: 40px;
    border-radius: 35px;
    border: 1px solid white;
    display: flex;
    align-items: center;
    margin-top: 12px;
    list-style-type: none;
    font-size: 15px;
    text-align: center;
    display: flex;
    justify-content: center;
    margin-left: -16px;
    @include font-header;
    color: $rollover;
    cursor: pointer;
    &:hover {
        color: white;
    }
}
.menu-item-selected {
    background-color: #60343c;
    width: 135px;
    height: 40px;
    border-radius: 35px;
    border: 1px solid white;
    display: flex;
    align-items: center;
    margin-top: 12px;
    list-style-type: none;
    font-size: 15px;
    text-align: center;
    display: flex;
    justify-content: center;
    margin-left: -16px;
    @include font-header;
    color: white;
    cursor: pointer;
    &:hover {
        color: white;
    }
}
.menu-dusk-sunset {
    background-color: #60343c;
    width: 135px;
    height: 40px;
    border-radius: 35px;
    border: 1px solid white;
    display: flex;
    align-items: center;
    margin-top: -250px;
    list-style-type: none;
    font-size: 15px;
    text-align: center;
    display: flex;
    justify-content: center;
    margin-left: -16px;
    @include font-header;
    color: $rollover;
}

.menu-item-dusk-sunset {
    padding-left: 4px;
    cursor: pointer;
    &:hover {
        color: white;
    }
}

.menu-dusk-sunset-single {
    background-color: #60343c;
    width: 134px;
    height: 40px;
    border-radius: 35px;
    border: 1px solid white;
    display: flex;
    align-items: center;
    margin-top: -250px;
    list-style-type: none;
    font-size: 15px;
    text-align: center;
    display: flex;
    justify-content: center;
    margin-left: -16px;
    @include font-header;
    color: $rollover;
}

.menu-item-dusk-sunset-selected {
    padding-left: 4px;
    cursor: pointer;
    color: white;
}