
@import "../../shared/shared.scss";

$fontSize: 17px;

$linkHeight: $fontSize * 1.5;
$linkOffset: -5px;

$logoHeight: 50px;
$logoOffset: -5px;

.header {
    z-index: $coreMenuZIndex;
    position: fixed;
    top: 0;
    width: 100%;
    height: $coreMenuHeight;
    background: $bkgColour;
    border-bottom: 2px solid black;
}


.header--nav {
    position: absolute;
    top: 0px;
    left: 40px;
    ul {
        margin: 0;
        padding: 0;
    }
}


    .header--nav__li {
        position: relative;
        display: inline-block;
        margin: 0px 99px 0px 0px;
        vertical-align: middle;
    }


    .header--nav__sub-menu {
        display: none; //displayed with JS
        position: absolute;
        left: -15px;
        top: 95%;
        width: 187px;
        padding: 10px;
        background: $bkgColour;
        border-radius: 0px 0px 10px 10px;
        text-align: center;
        border: 2px solid black;
        border-top: 2px solid white;
        margin-top: 19px;
        // filter: drop-shadow(3px 3px 2px rgb(31, 31, 31));
    }


    .header--nav__link {
        $linkPaddingTop: ( ($coreMenuHeight - $linkHeight) / 2 ) + $linkOffset;
        $linkPaddingBottom: ( ($coreMenuHeight - $linkHeight) / 2 ) - $linkOffset;

        display: block;
        margin: 0px;
        padding: #{ $linkPaddingTop } 28px #{ $linkPaddingBottom };
        // padding: 40px 25px;
        color: $highlightTextColour;
        text-transform: uppercase;
        text-decoration: none;
        @include font-header;
        transition: all 0.3s ease-out;
        font-size: $fontSize;
        &:hover {
            color: $highlightTextColour;

            text-decoration: underline;
            text-underline-offset: 10px;
        }
    }

    .header--nav__link-selected {
        @extend .header--nav__link;
        text-decoration: underline;
        text-underline-offset: 10px;
        &:hover {
            color: $highlightTextColour;

            text-decoration: underline;
            text-underline-offset: 10px;
        }
    }


        .header--nav__link-in-sub-menu {
            @extend .header--nav__link;
            height: auto;
            padding: 10px 10px;
        }


    .header--nav__link-logo { 
        $logoPaddingTop: ( ($coreMenuHeight - $logoHeight) / 2 ) + $logoOffset;
        $logoPaddingBottom: ( ($coreMenuHeight - $logoHeight) / 2 ) - $logoOffset;
        padding-left: 100px;
        @extend .header--nav__link;
        padding-top: #{ $logoPaddingTop };
        padding-bottom: #{ $logoPaddingBottom };
        
        img {
            max-height: $logoHeight;
            width: auto;
        }
    }


 